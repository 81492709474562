import React, { useState, useEffect } from 'react';
import { useParams, useNavigate } from 'react-router-dom';
import axios from 'axios';
import ImageSlider from './ImageSlider';
import { useCart } from '../context/CartContext';

const ProductDetails = () => {
  const { id } = useParams();
  const navigate = useNavigate();
  const { addToCart } = useCart();
  const [product, setProduct] = useState(null);
  const [moreProducts, setMoreProducts] = useState([]);
  const [currentProductIndex, setCurrentProductIndex] = useState(0);
  const backendUrl = process.env.REACT_APP_BACKEND_URL;

  useEffect(() => {
    const fetchProduct = async () => {
      try {
        const response = await axios.get(`${backendUrl}/products/${id}`);
        setProduct(response.data);
      } catch (error) {
        console.error('Error fetching product:', error);
      }
    };

    const fetchMoreProducts = async () => {
      try {
        const response = await axios.get(`${backendUrl}/products`);
        setMoreProducts(response.data.filter(p => p.id !== id));
      } catch (error) {
        console.error('Error fetching more products:', error);
      }
    };

    fetchProduct();
    fetchMoreProducts();
  }, [id, backendUrl]);

  useEffect(() => {
    const timer = setInterval(() => {
      setCurrentProductIndex((prevIndex) => 
        (prevIndex + 1) % Math.max(moreProducts.length - 2, 1)
      );
    }, 5000);

    return () => clearInterval(timer);
  }, [moreProducts]);

  if (!product) {
    return <div>Loading...</div>;
  }

  const handleAddToCart = () => {
    addToCart(product);
  };

  const handleProductClick = (productId) => {
    navigate(`/product/${productId}`);
  };

  return (
    <div className="min-h-screen flex flex-col bg-gray-50">
      <div className="container mx-auto flex flex-col md:flex-row py-9 px-9 md:px-9">
        <div className="w-full md:w-2/1 mb-9 md:mb-0">
          <div className="current-product-image-container bg-pink-100 rounded-lg p-2">
            <ImageSlider images={product.images} interval={3000} />
          </div>
        </div>
        <div className="w-full md:w-1/2 md:pl-6">
          <div className="bg-white shadow-lg rounded-lg p-6">
            <h2 className="text-3xl md:text-4xl font-bold mb-4 text-gray-900">{product.name}</h2>
            <p className="text-2xl md:text-3xl text-gray-700 mb-4">₹{product.price.toFixed(2)}</p>
            <p className="text-base md:text-lg text-gray-700 mb-6">{product.description}</p>
            <div className="space-y-4 md:space-y-6">
              <div>
                <h3 className="text-lg md:text-xl font-semibold text-gray-900">Product Information</h3>
                <p className="text-sm md:text-base text-gray-600 mt-2 text-justify">{product.detailDescription2}</p>
              </div>
              <div>
                <h3 className="text-lg md:text-xl font-semibold text-gray-900 text-justify">Shipping Policy</h3>
                <p className="text-sm md:text-base text-gray-600 mt-2">Shipping will be calculater after order is confirmed.</p>
              </div>
              <div>
                <h3 className="text-lg md:text-xl font-semibold text-gray-900 text-justify">Returns & Refunds</h3>
                <p className="text-sm md:text-base text-gray-600 mt-2">You can call or text us on WhatsApp for shipping details and payment confirmation. Payment processing may take 1-2 days. If not received, the order will be cancelled. In case of cancellation, payment will be refunded within 7 business days.</p>
              </div>
            </div>
            <button onClick={handleAddToCart} className="w-full mt-6 md:mt-8 bg-purple-500 text-white px-8 py-3 rounded-lg hover:bg-purple-600 transition duration-300">
              ADD TO CART
            </button>
          </div>
        </div>
      </div>
      
      {/* More Products Card */}
      <div className="container mx-auto mt-8 bg-white shadow-lg rounded-lg p-4">
        <h3 className="text-xl font-semibold text-gray-900 mb-4 text-center">More Products</h3>
        <div className="flex overflow-hidden">
          {moreProducts.slice(currentProductIndex, currentProductIndex + 3).map((moreProduct) => (
            <div key={moreProduct.id} className="w-1/3 px-2 cursor-pointer" onClick={() => handleProductClick(moreProduct.id)}>
              <div className="aspect-w-1 aspect-h-1 w-full overflow-hidden rounded-lg">
                <img src={moreProduct.images[0]} alt={moreProduct.name} className="w-full h-full object-cover object-center" />
              </div>
              <p className="mt-2 text-sm font-medium text-gray-900 text-center truncate">{moreProduct.name}</p>
              <p className="text-sm text-gray-600 text-center ">₹{moreProduct.price.toFixed(2)}</p>
            </div>
          ))}
        </div>
      </div>
    </div>
  );
};

export default ProductDetails;