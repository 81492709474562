import React, { useState, useEffect } from 'react';
import { motion, AnimatePresence } from 'framer-motion';
import { FaLinkedin } from 'react-icons/fa';

const backgroundImages = [
  "https://res.cloudinary.com/devunique/image/upload/f_auto,q_auto/v1/Product_image/About%20Us/ym4giqxbna17f0hiaqjc",
  "https://res.cloudinary.com/devunique/image/upload/f_auto,q_auto/v1/Product_image/About%20Us/eckox0wol5azllc8tp18",
  "https://res.cloudinary.com/devunique/image/upload/f_auto,q_auto/v1/Product_image/About%20Us/ibtrcb6q14kkfddk2avd",
  "https://res.cloudinary.com/devunique/image/upload/f_auto,q_auto/v1/Product_image/About%20Us/iy7ra7ovhzijpfuv9mnt",
  "https://res.cloudinary.com/devunique/image/upload/f_auto,q_auto/v1/Product_image/About%20Us/m2lfowiflvn6d2sqxoaq",
  "https://res.cloudinary.com/devunique/image/upload/f_auto,q_auto/v1/Product_image/About%20Us/cwacej9vdgfntgkgu6h3",
  "https://res.cloudinary.com/devunique/image/upload/f_auto,q_auto/v1/Product_image/About%20Us/piftjlk7diwjpi7eztcg",
  "https://res.cloudinary.com/devunique/image/upload/f_auto,q_auto/v1/Product_image/About%20Us/eskc2p739q3sum0c8szi",
  "https://res.cloudinary.com/devunique/image/upload/f_auto,q_auto/v1/Product_image/About%20Us/e3fbwvs53qsvzkxae391",
  "https://res.cloudinary.com/devunique/image/upload/f_auto,q_auto/v1/Product_image/About%20Us/r5oxy5t5l5e9xdiz5ybw"
];

const sectionImages = [
  "https://res.cloudinary.com/devunique/image/upload/f_auto,q_auto/v1/Product_image/About%20Us/d9bvwkiscjergsz2mek8",
  "https://res.cloudinary.com/devunique/image/upload/f_auto,q_auto/v1/Product_image/About%20Us/eckox0wol5azllc8tp18",
  "https://res.cloudinary.com/devunique/image/upload/f_auto,q_auto/v1/Product_image/About%20Us/xzllbxuxm6cadnm11mxd",
  "https://res.cloudinary.com/devunique/image/upload/f_auto,q_auto/v1/Product_image/About%20Us/atmxtmahpvmdnknjlw1f",
  "https://res.cloudinary.com/devunique/image/upload/f_auto,q_auto/v1/Product_image/About%20Us/eskc2p739q3sum0c8szi",
  "https://res.cloudinary.com/devunique/image/upload/f_auto,q_auto/v1/Product_image/About%20Us/piftjlk7diwjpi7eztcg"
];

const AboutUs = () => {
  const [currentBgIndex, setCurrentBgIndex] = useState(0);
  const [currentSectionImages, setCurrentSectionImages] = useState([...sectionImages]);

  useEffect(() => {
    const intervalBg = setInterval(() => {
      setCurrentBgIndex((prevIndex) => (prevIndex + 1) % backgroundImages.length);
    }, 10000); // Change background image every 10 seconds

    const intervalSections = setInterval(() => {
      setCurrentSectionImages(prevImages => {
        const newImages = [...prevImages];
        for (let i = newImages.length - 1; i > 0; i--) {
          const j = Math.floor(Math.random() * (i + 1));
          [newImages[i], newImages[j]] = [newImages[j], newImages[i]];
        }
        return newImages;
      });
    }, 50000); // Change section images every 5 seconds

    return () => {
      clearInterval(intervalBg);
      clearInterval(intervalSections);
    };
  }, []);

  const cardVariants = {
    offscreen: {
      y: 300,
      opacity: 0
    },
    onscreen: {
      y: 0,
      opacity: 1,
      transition: {
        type: "spring",
        bounce: 0.4,
        duration: 0.8
      }
    }
  };

  const directorVariants = {
    offscreen: {
      scale: 0.8,
      opacity: 0
    },
    onscreen: {
      scale: 1,
      opacity: 1,
      transition: {
        type: "spring",
        bounce: 0.4,
        duration: 1
      }
    }
  };

  const sections = [
    { title: "Our Mission", content: "To be an excellent supplier of bio-pesticides which are effective, non-poisonous, eco-friendly, zero-residue and cost effective. Deliver need based solutions that will please our customers." },
    { title: "Our Vision", content: "Leadership in providing latest crop management solutions. Increasing farm productivity & profitability with cost effective trusted technologies." },
    { title: "Our Values", content: "We want to be best in our relationships, integrity, honesty, humility and hard work." },
    { title: "Success Story", content: "Unique Agrisciences(India) Pvt. Ltd., established by entrepreneurs with over 17 years of experience in Agri-inputs, has been continuously endeavoring to achieve our goal." },
    { title: "Research & Innovation", content: "We are in the field of research and production of Bio-Pesticides which are very effective for different crop pest Management complexes." },
    { title: "Our Commitment", content: "Our aim is to provide farmers with excellent Agri-inputs at reasonable prices. We are committed to being 'UNIQUE' as our brand denotes, to all our customers globally." },
  ];

  return (
    <div className="relative min-h-screen overflow-hidden">
      <AnimatePresence initial={false}>
        <motion.div
          key={currentBgIndex}
          className="absolute inset-0 bg-cover bg-center"
          style={{ backgroundImage: `url(${backgroundImages[currentBgIndex]})` }}
          initial={{ opacity: 0 }}
          animate={{ opacity: 1 }}
          exit={{ opacity: 0 }}
          transition={{ duration: 1 }}
        />
      </AnimatePresence>
      <div className="absolute inset-0 bg-black bg-opacity-50" />
      <div className="relative container mx-auto px-4 py-16 text-white">
        <motion.h1 
          className="text-6xl font-extrabold text-center mb-12 text-transparent bg-clip-text bg-gradient-to-r from-yellow-400 to-pink-500"
          initial={{ opacity: 0, scale: 0.5 }}
          animate={{ opacity: 1, scale: 1 }}
          transition={{ duration: 0.8, ease: "easeOut" }}
        >
          
        </motion.h1>

        <div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-3 gap-8">
          {sections.map((item, index) => (
            <motion.div 
              key={index}
              className="bg-white bg-opacity-80 rounded-lg shadow-xl overflow-hidden text-center"
              variants={cardVariants}
              initial="offscreen"
              whileInView="onscreen"
              viewport={{ once: true, amount: 0.8 }}
            >
              <motion.div
                className="w-full h-48 bg-cover bg-center"
                style={{ backgroundImage: `url(${currentSectionImages[index]})` }}
                whileHover={{ scale: 1.1 }}
                transition={{ duration: 0.3 }}
              />
              <motion.div 
                className="p-6"
                initial={{ opacity: 0, y: 20 }}
                whileInView={{ opacity: 1, y: 0 }}
                transition={{ delay: 0.2, duration: 0.5 }}
              >
                <h3 className="text-2xl font-bold text-gray-800 mb-3">{item.title}</h3>
                <p className="text-gray-700">{item.content}</p>
              </motion.div>
            </motion.div>
          ))}
        </div>

        <motion.div 
          className="mt-16 bg-white bg-opacity-80 rounded-lg shadow-xl p-8"
          variants={directorVariants}
          initial="offscreen"
          whileInView="onscreen"
          viewport={{ once: true, amount: 0.2 }}
        >
          <div className="flex flex-col md:flex-row items-center">
            <div className="md:w-1/3 mb-6 md:mb-0">
              <motion.img 
                src="https://res.cloudinary.com/devunique/image/upload/f_auto,q_auto/v1/Product_image/About%20Us/uveolujvm12bqzs3gcb6" 
                alt="Director"
                className="rounded-full w-64 h-64 object-cover mx-auto border-4 border-green-500 shadow-lg"
                whileHover={{ scale: 1.1, rotate: 5 }}
                transition={{ duration: 0.3 }}
              />
            </div>
            <div className="md:w-2/3 md:pl-8">
              <h2 className="text-4xl font-bold text-gray-800 mb-6">Message from Director's Desk</h2>
              <div className="text-gray-700 space-y-4 text-justify">
                <p>Growing economy with increasing awareness of health related effects of chemical pesticides has increased the demand of organic food. In view of this demand and the government's efforts to mitigate climate change, biopesticides are going to play an important role in future pest management programmes.</p>
                <p>The pesticides market in India is about Rs. 8,500 crores and growing at the rate of 6-8 % p.a. However, the awareness against the pesticides residue and the increase in export of fruits and vegetables from India has adversely affected the chemical pesticides business and its growth.</p>
                <p>Our business opportunity is to convert farmers into using zero-residue Biocides and biofertilizers to ensure a safe produce.</p>
                <p>We owe our success to our customers for their trust and understanding and to our employees who have been the backbone of our leading edge performance. With their support the future holds a new promise for Unique Agrisciences as the leading bio-technology company.</p>
                <p className="font-semibold text-xl">With regards,<br/>Prasant Kumar Raut</p>
                <p className='text-justify'><a href='https://in.linkedin.com/in/prasant-kumar-raut-54834419'><FaLinkedin size={35}/></a></p>
              </div>
            </div>
          </div>
        </motion.div>
      </div>
    </div>
  );
};

export default AboutUs;