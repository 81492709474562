import React from 'react';
import { Link } from 'react-router-dom';


function HeroSection() {
  return (
    <div className="relative bg-cover bg-center min-h-screen flex items-center justify-center overflow-hidden transition-all duration-700 ease-in-out animate-gradient-x">
      <div 
        className="absolute inset-0 bg-cover bg-center"
        style={{ backgroundImage: `url('https://res.cloudinary.com/devunique/image/upload/f_auto,q_auto/v1/Product_image/Icons/background')` }}
      >
        <div className="absolute inset-0 bg-black bg-opacity-50 backdrop-blur-sm"></div>
      </div>
      
      <div className="relative z-10 text-center text-white px-4 sm:px-6 lg:px-8 max-w-3xl mx-auto">
  <img 
    src="https://res.cloudinary.com/devunique/image/upload/f_auto,q_auto/v1/Product_image/Icons/ov9srlraaqg6qfgkddsm" 
    alt="Logo" 
    className="mx-auto mb-8 w-60 h-60 sm:w-80 sm:h-80 shadow-2xl border-4 border-transparent transition-all duration-500 ease-in-out transform hover:scale-105 animate-float"/>

        
        <h1 className="text-4xl sm:text-5xl md:text-6xl font-bold mb-4 animate-fade-in-down">
          <span className="animate-text-glow">Agri</span>
          <span className="animate-color-cycle">sciences</span>
        </h1>
        
        <p className="text-xl sm:text-2xl md:text-3xl font-medium mb-8 animate-fade-in-up">
          India Pvt. Ltd
        </p>
        
        <div className="flex flex-col sm:flex-row justify-center space-y-4 sm:space-y-0 sm:space-x-4">
          <Link to="/products">
            <button className="bg-orange-500 hover:bg-orange-600 text-white font-bold py-3 px-6 rounded-lg shadow-lg transition duration-300 ease-in-out transform hover:-translate-y-1 hover:scale-105 focus:outline-none focus:ring-2 focus:ring-orange-500 focus:ring-opacity-50 animate-pulse-slow">
              Products
            </button>
          </Link>
          
        </div>
      </div>
    </div>
  );
}

export default HeroSection;