import React from 'react';
import { useCart } from '../context/CartContext';

const CartItem = ({ item }) => {
  const { updateCartItemQuantity, removeFromCart } = useCart();

  const handleQuantityChange = (e) => {
    const newQuantity = parseInt(e.target.value, 10);
    updateCartItemQuantity(item.id, newQuantity);
  };

  return (
    <li className="flex items-center justify-between p-4 bg-white rounded-lg shadow-md">
      <div>
        <h4 className="text-lg font-bold">{item.name}</h4>
        <p className="text-gray-600">₹{item.price.toFixed(2)}</p>
      </div>
      <div className="flex items-center">
        <input
          type="number"
          value={item.quantity}
          onChange={handleQuantityChange}
          className="w-16 p-1 text-center border rounded-md"
          min="1"
        />
        <button
          onClick={() => removeFromCart(item.id)}
          className="ml-4 bg-red-500 text-white px-2 py-1 rounded-md hover:bg-red-600 transition duration-300"
        >
          Remove
        </button>
      </div>
    </li>
  );
};

export default CartItem;
