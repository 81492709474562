import React, { useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { motion, AnimatePresence } from 'framer-motion';
import { CheckCircle, Truck, CreditCard, Package, AlertCircle } from 'lucide-react';
import ShippingForm from '../components/ShippingForm';
import generateOrderId from '../utils/orderIdGenerator';
import axios from 'axios';
import { useCart } from '../context/CartContext';

function Checkout() {
  const [showConfirmation, setShowConfirmation] = useState(false);
  const [orderDetails, setOrderDetails] = useState(null);
  const { cart, clearCart } = useCart();
  const navigate = useNavigate();

  const handleShippingSubmit = async (shippingDetails) => {
    try {
      const newOrderId = generateOrderId();
      //console.log('Current cart:', cart); // Log the cart to see its contents

      // Prepare cart items
      const cartItems = cart.map(item => ({
        id: item.id,
        name: item.name,
        price: item.price,
        quantity: item.quantity
      }));
     // console.log('Prepared cart items:', cartItems); // Log the prepared cart items

      // Send cart items to the backend
      const cartData = {
        orderId: newOrderId,
        items: cartItems
      };
     // console.log('Cart data being sent:', cartData); // Log the final cart data being sent

      const cartResponse = await axios.post(`${process.env.REACT_APP_BACKEND_URL}/cart`, cartData);
      //console.log('Cart response:', cartResponse.data); // Log the response from the cart endpoint

      // Send shipping details along with the orderId to the backend
      const shippingInfo = {
        ...shippingDetails,
        orderId: newOrderId
      };
      const shippingResponse = await axios.post(`${process.env.REACT_APP_BACKEND_URL}/shippings`, shippingInfo);
      //console.log('Shipping response:', shippingResponse.data); // Log the response from the shipping endpoint

      setOrderDetails({
        orderId: newOrderId,
        subtotal: cart.reduce((total, item) => total + item.price * item.quantity, 0),
        shippingAddress: `${shippingDetails.address}, ${shippingDetails.city}, ${shippingDetails.zipCode}`,
        createdAt: new Date().toISOString()
      });

      setShowConfirmation(true);
    } catch (error) {
      console.error('Error submitting order:', error);
      // Handle error (e.g., show error message to user)
    }
  };

  const handleGoBack = () => {
    if (typeof clearCart === 'function') {
      clearCart();
    } else {
      console.warn('clearCart function is not available in CartContext');
    }
    navigate('/');
  };

  return (
    <div 
      className="min-h-screen flex items-center justify-center p-4 bg-cover bg-center"
      style={{
        backgroundImage: `url('https://res.cloudinary.com/devunique/image/upload/f_auto,q_auto/v1/Product_image/Icons/p1')`,
        backgroundBlendMode: 'overlay',
        backgroundColor: 'rgba(0, 0, 0, 0.4)'
      }}
    >
      <motion.div
        initial={{ opacity: 0, y: 20 }}
        animate={{ opacity: 1, y: 0 }}
        exit={{ opacity: 0, y: -20 }}
        transition={{ duration: 0.5 }}
        className="w-full max-w-md bg-white bg-opacity-95 rounded-2xl shadow-2xl overflow-hidden"
      >
        <div className="bg-gradient-to-r from-green-500 to-orange-500 p-6 text-white">
          <h1 className="text-3xl font-bold text-center">{showConfirmation ? "Order Received" : "Checkout"}</h1>
          <p className="mt-2 opacity-80 text-center">{showConfirmation ? "Thank you for your purchase!" : "Complete your order"}</p>
        </div>
        
        <AnimatePresence mode="wait">
          {!showConfirmation ? (
            <motion.div
              key="shipping-form"
              initial={{ opacity: 0 }}
              animate={{ opacity: 1 }}
              exit={{ opacity: 0 }}
              transition={{ duration: 0.3 }}
              className="p-6"
            >
              <ShippingForm onSubmit={handleShippingSubmit} />
            </motion.div>
          ) : (
            <motion.div
              key="confirmation"
              initial={{ opacity: 0 }}
              animate={{ opacity: 1 }}
              exit={{ opacity: 0 }}
              transition={{ duration: 0.3 }}
              className="p-6 space-y-6"
            >
              <div className="flex justify-center">
                <motion.div
                  initial={{ scale: 0 }}
                  animate={{ scale: 1 }}
                  transition={{ type: "spring", stiffness: 260, damping: 20 }}
                >
                  <CheckCircle className="text-green-500 w-20 h-20" />
                </motion.div>
              </div>
              
              <div className="space-y-4">
                <ConfirmationItem
                  icon={<Package className="text-orange-500" />}
                  title="Order Summary"
                  content={`Subtotal: ₹${orderDetails.subtotal.toFixed(2)}`}
                />
                <ConfirmationItem
                  icon={<Truck className="text-orange-500" />}
                  title="Shipping Information"
                  content={orderDetails.shippingAddress}
                />
                <ConfirmationItem
                  icon={<CreditCard className="text-orange-500" />}
                  title="Order Details"
                  content={`Order ID: ${orderDetails.orderId}\nDate: ${new Date(orderDetails.createdAt).toLocaleDateString()}`}
                />
                <ConfirmationItem
                  icon={<CreditCard className="text-orange-500" />}
                  title="Bank Details"
                  content={`Account: Unique Agrisciences India Pvt Ltd\nBank Name: IDBI Bank, Janpath branch Bhubaneswar\nIFSC Code: IBKL0000042\n Account Number: 0042102000040017`}
                  

                />
              </div>

              <div className="bg-yellow-100 border-l-4 border-yellow-500 p-4 mt-6">
                <div className="flex items-center mb-2">
                  <AlertCircle className="text-yellow-500 mr-2" />
                  <p className="font-semibold text-yellow-700">Important Information</p>
                </div>
                <p className="text-sm text-yellow-700 text-bold text-justify">
                  Please take a screenshot for future communication. You can call or text us on WhatsApp for shipping details and payment confirmation. Payment processing may take 1-2 days. If not received, the order will be cancelled. In case of cancellation, payment will be refunded within 7 business days.
                </p>
              </div>

              <p className="text-center text-gray-600">
                Thank you for Trusting us. 🙏
              </p>

              <motion.button
                whileHover={{ scale: 1.05 }}
                whileTap={{ scale: 0.95 }}
                onClick={handleGoBack}
                className="w-full bg-gradient-to-r from-green-500 to-orange-500 text-white py-3 rounded-lg font-semibold shadow-md hover:from-green-600 hover:to-orange-600 transition duration-300"
              >
                Back to Homepage
              </motion.button>
            </motion.div>
          )}
        </AnimatePresence>
      </motion.div>
    </div>
  );
}

const ConfirmationItem = ({ icon, title, content }) => (
  <div className="flex items-start space-x-3">
    <div className="flex-shrink-0">{icon}</div>
    <div>
      <h3 className="font-semibold text-gray-800">{title}</h3>
      <p className="text-gray-600 whitespace-pre-line">{content}</p>
    </div>
  </div>
);

export default Checkout;