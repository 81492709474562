import React, { useRef, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';

function ProductsSection() {
    return (
        <div className="container mx-auto py-8 md:py-16 px-4 space-y-8 md:space-y-16">
            <VideoSection />
            <ProductList />
        </div>
    );
}

function VideoSection() {
    const videoRef = useRef(null);

    useEffect(() => {
        const playVideo = async () => {
            if (videoRef.current) {
                try {
                    await videoRef.current.play();
                } catch (error) {
                    console.error("Autoplay failed:", error);
                    // If autoplay fails, we'll try to play muted, then unmute
                    try {
                        videoRef.current.muted = true;
                        await videoRef.current.play();
                        videoRef.current.muted = false;
                    } catch (mutedError) {
                        console.error("Muted autoplay failed:", mutedError);
                    }
                }
            }
        };

        playVideo();

        // Try to play video on user interaction
        const handleInteraction = () => {
            if (videoRef.current && videoRef.current.paused) {
                playVideo();
            }
        };

        document.addEventListener('click', handleInteraction);
        document.addEventListener('touchstart', handleInteraction);

        return () => {
            document.removeEventListener('click', handleInteraction);
            document.removeEventListener('touchstart', handleInteraction);
        };
    }, []);

    return (
        <div className="relative overflow-hidden rounded-xl">
            <video 
                ref={videoRef}
                className="w-full h-auto object-cover"
                autoPlay 
                loop 
                playsInline
                controls
            >
                <source src="https://res.cloudinary.com/devunique/video/upload/f_auto:video,q_auto/v1/Product_image/About%20Us/fn4g9kg6pybnnugwudjg" type="video/mp4" />
                Your browser does not support the video tag.
            </video>
        </div>
    );
}

function ProductList() {
    return (
        <div>
            <h2 className="text-2xl md:text-3xl font-bold text-center mb-6 md:mb-10 text-gray-800">Our Products</h2>
            <div className="grid grid-cols-1 sm:grid-cols-2 lg:grid-cols-3 gap-6 md:gap-8">
                {product.map(product => (
                    <ProductCard key={product.id} product={product} />
                ))}
            </div>
        </div>
    );
}

const product = [
    { id: 1, name: 'Guru 250 ml', price: 950, imageUrl: 'https://res.cloudinary.com/devunique/image/upload/f_auto,q_auto/v1/Product_image/1/bsfdt9ctoqjkkdisew5i' },
    { id: 2, name: 'Ultima-789', price: 265, imageUrl: 'https://res.cloudinary.com/devunique/image/upload/f_auto,q_auto/v1/Product_image/2/Ultima-1' },
    { id: 3, name: 'Anti-Virous', price: 450, imageUrl: 'https://res.cloudinary.com/devunique/image/upload/f_auto,q_auto/v1/Product_image/3/antivirus-1' },
    { id: 4, name: 'M-Power', price: 162, imageUrl: 'https://res.cloudinary.com/devunique/image/upload/f_auto,q_auto/v1/Product_image/5/mpower1' },
    { id: 5, name: 'Viswas', price: 385, imageUrl: 'https://res.cloudinary.com/devunique/image/upload/f_auto,q_auto/v1/Product_image/6/V1' },
    { id: 6, name: 'A-star', price: 418, imageUrl: 'https://res.cloudinary.com/devunique/image/upload/f_auto,q_auto/v1/Product_image/7/AStar1' },
];

function ProductCard({ product }) {
    const navigate = useNavigate();
    return (
        <div className="border border-gray-200 p-4 md:p-6 rounded-xl text-center bg-white">
            <img src={product.imageUrl} alt={product.name} className="mb-4 md:mb-6 w-full h-40 md:h-48 object-cover rounded-lg" />
            <h3 className="text-lg md:text-xl font-semibold mb-2 text-gray-800">{product.name}</h3>
            <p className="text-xl md:text-2xl font-bold text-green-600 mb-4">₹{product.price}</p>
            <button 
                onClick={() => navigate(`/products/${product.id}`)} 
                className="bg-green-500 hover:bg-green-600 text-white font-bold py-2 px-4 md:py-3 md:px-6 rounded-full transition duration-300 ease-in-out text-sm md:text-base"
            >
                View Details
            </button>
        </div>
    );
}

export default ProductsSection;