import React from 'react';

function FeaturesSection() {
    return (
        <div className="container mx-auto text-center py-16">
            <h2 className="text-3xl font-bold mb-6">Why us?</h2>
            <div className="flex justify-center space-x-10">
                <Feature icon="https://res.cloudinary.com/devunique/image/upload/f_auto,q_auto/v1/Product_image/Icons/natural" title="Natural Process"  />
                <Feature icon="https://res.cloudinary.com/devunique/image/upload/v1719569745/Product_image/Icons/Tractor1.avif" title="Organic Products"/>
                <Feature icon="https://res.cloudinary.com/devunique/image/upload/v1719569010/Product_image/Icons/Bio-1.png" title="Biologically Safe" />
            </div>
        </div>
    );
}


function Feature({ icon, title, description }) {
    return (
        <div className="flex flex-col items-center">
            <img src={icon} alt={title} className="mb-5 w-28 h-28"/>
            <h3 className="text-xl font-semibold">{title}</h3>
            <p>{description}</p>
        </div>
    );
}

export default FeaturesSection;
