import React, { useState, useEffect, useRef } from 'react';
import { Link, useLocation } from 'react-router-dom';
import { useAuth0 } from '@auth0/auth0-react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faShoppingCart, faBars, faTimes } from '@fortawesome/free-solid-svg-icons';
import { useCart } from '../context/CartContext';

const NavBar = () => {
  const { isAuthenticated, user, loginWithRedirect, logout } = useAuth0();
  const { cart } = useCart();
  const [isMobileMenuOpen, setIsMobileMenuOpen] = useState(false);
  const [isScrolled, setIsScrolled] = useState(false);
  const mobileMenuRef = useRef(null);
  const location = useLocation();

  useEffect(() => {
    const handleScroll = () => {
      setIsScrolled(window.scrollY > 10);
    };

    const handleClickOutside = (event) => {
      if (mobileMenuRef.current && !mobileMenuRef.current.contains(event.target)) {
        setIsMobileMenuOpen(false);
      }
    };

    window.addEventListener('scroll', handleScroll);
    document.addEventListener('mousedown', handleClickOutside);

    return () => {
      window.removeEventListener('scroll', handleScroll);
      document.removeEventListener('mousedown', handleClickOutside);
    };
  }, []);

  useEffect(() => {
    // Close mobile menu when location changes
    setIsMobileMenuOpen(false);
  }, [location]);

  const closeMobileMenu = () => {
    setIsMobileMenuOpen(false);
  };

  return (
    <nav className={`fixed top-0 left-0 right-0 z-50 transition-all duration-300 ${isScrolled ? 'bg-white/90 shadow-md' : 'bg-gradient-to-b from-black/40 to-transparent'}`}>
      <div className="container mx-auto px-4 sm:px-6 lg:px-8">
        <div className="flex items-center justify-between h-16 sm:h-20">
          <Link to="/" className="flex-shrink-0">
            <h1 className="text-xl font-bold animate-colorChange">Unique Agrisciences</h1>
          </Link>
          <div className="hidden md:block flex-grow">
            <div className="ml-0 flex items-center justify-center space-x-4">
              <NavLink to="/" label="Home" isScrolled={isScrolled} />
              <NavLink to="/about" label="About Us" isScrolled={isScrolled} />
              <NavLink to="/products" label="Products" isScrolled={isScrolled} />
              <NavLink to="/contact" label="Contact Us" isScrolled={isScrolled} />
            </div>
          </div>
          <div className="hidden md:flex md:items-center md:space-x-4">
            <CartIcon cart={cart} isScrolled={isScrolled} />
            <AuthButton isAuthenticated={isAuthenticated} user={user} loginWithRedirect={loginWithRedirect} logout={logout} isScrolled={isScrolled} />
          </div>
          <div className="md:hidden">
            <button
              onClick={() => setIsMobileMenuOpen(!isMobileMenuOpen)}
              className={`inline-flex items-center justify-center p-2 rounded-md ${isScrolled ? 'text-gray-800 hover:text-gray-600 hover:bg-gray-200' : 'text-white hover:text-gray-300 hover:bg-white/20'} focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-offset-gray-800 focus:ring-white`}
            >
              <span className="sr-only">Open main menu</span>
              <FontAwesomeIcon icon={isMobileMenuOpen ? faTimes : faBars} className="block h-6 w-6" aria-hidden="true" />
            </button>
          </div>
        </div>
      </div>

      <div 
        ref={mobileMenuRef}
        className={`md:hidden fixed inset-0 bg-black bg-opacity-75 z-50 transition-opacity duration-300 ${isMobileMenuOpen ? 'opacity-100' : 'opacity-0 pointer-events-none'}`}
      >
        <div className="flex flex-col items-center justify-center h-full space-y-8 relative p-4">
          <button
            onClick={closeMobileMenu}
            className="absolute top-4 right-4 text-white hover:text-gray-300 transition-colors duration-200 z-10"
          >
            <FontAwesomeIcon icon={faTimes} className="h-6 w-6" />
          </button>
          <MobileNavLink to="/" label="Home" closeMobileMenu={closeMobileMenu} />
          <MobileNavLink to="/about" label="About Us" closeMobileMenu={closeMobileMenu} />
          <MobileNavLink to="/products" label="Products" closeMobileMenu={closeMobileMenu} />
          <MobileNavLink to="/contact" label="Contact Us" closeMobileMenu={closeMobileMenu} />
          <div className="flex flex-col items-center space-y-4">
            <CartIcon cart={cart} isScrolled={false} closeMobileMenu={closeMobileMenu} />
            <MobileAuthButton 
              isAuthenticated={isAuthenticated} 
              user={user} 
              loginWithRedirect={loginWithRedirect} 
              logout={logout} 
              closeMobileMenu={closeMobileMenu}
            />
          </div>
        </div>
      </div>
    </nav>
  );
};

const NavLink = ({ to, label, isScrolled }) => (
  <Link
    to={to}
    className={`px-3 py-2 rounded-md text-sm font-medium ${
      isScrolled
        ? 'text-gray-800 hover:bg-gray-200 hover:text-gray-900'
        : 'text-white hover:bg-white/20 hover:text-gray-100'
    } transition-colors duration-200`}
  >
    {label}
  </Link>
);

const MobileNavLink = ({ to, label, closeMobileMenu }) => (
  <Link
    to={to}
    className="text-white text-xl hover:text-gray-300 transition-colors duration-200"
    onClick={closeMobileMenu}
  >
    {label}
  </Link>
);

const CartIcon = ({ cart, isScrolled, closeMobileMenu }) => (
  <Link to="/cart" className="relative" onClick={closeMobileMenu}>
    <FontAwesomeIcon icon={faShoppingCart} className={`h-6 w-6 ${isScrolled ? 'text-gray-800' : 'text-white'}`} />
    {cart.length > 0 && (
      <span className="absolute -top-2 -right-2 inline-flex items-center justify-center px-2 py-1 text-xs font-bold leading-none text-red-100 bg-red-600 rounded-full">
        {cart.length}
      </span>
    )}
  </Link>
);

const AuthButton = ({ isAuthenticated, user, loginWithRedirect, logout, isScrolled }) => (
  isAuthenticated ? (
    <div className="flex items-center space-x-2">
      <img className="h-8 w-8 rounded-full" src={user.picture} alt="" />
      <button
        onClick={() => logout({ returnTo: window.location.origin })}
        className={`${isScrolled ? 'text-gray-800 hover:text-gray-600' : 'text-white hover:text-gray-300'} transition-colors duration-200`}
      >
        Logout
      </button>
    </div>
  ) : (
    <button
      onClick={() => loginWithRedirect()}
      className={`${isScrolled ? 'text-gray-800 hover:text-gray-600' : 'text-white hover:text-gray-300'} transition-colors duration-200`}
    >
      Login
    </button>
  )
);

const MobileAuthButton = ({ isAuthenticated, user, loginWithRedirect, logout, closeMobileMenu }) => (
  isAuthenticated ? (
    <div className="flex flex-col items-center space-y-2">
      <img className="h-10 w-10 rounded-full" src={user.picture} alt="" />
      <button
        onClick={() => {
          logout({ returnTo: window.location.origin });
          closeMobileMenu();
        }}
        className="text-white hover:text-gray-300 transition-colors duration-200"
      >
        Logout
      </button>
    </div>
  ) : (
    <button
      onClick={() => {
        loginWithRedirect();
        closeMobileMenu();
      }}
      className="text-white hover:text-gray-300 transition-colors duration-200"
    >
      Login
    </button>
  )
);

export default NavBar;