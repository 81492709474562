// src/utils/orderIdGenerator.js

function generateOrderId() {
    const randomPrefix = Math.floor(10 + Math.random() * 90); // Random 2-digit number
    const date = new Date();
    const month = String(date.getMonth() + 1).padStart(2, '0');
    const year = date.getFullYear();
    const day  = date.getDate();
    const orderNumber = String(Math.floor(1000 + Math.random() * 9000)); // Random 4-digit number for this example
  
    return `${randomPrefix}${day}${month}${year}${orderNumber}`;
  }
  
  export default generateOrderId;