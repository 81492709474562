import React, { useState } from 'react';
import axios from 'axios';
import { useAuth0 } from '@auth0/auth0-react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faSpinner } from '@fortawesome/free-solid-svg-icons';

const ShippingForm = ({ onSubmit }) => {
  const { isAuthenticated, isLoading, loginWithRedirect, getAccessTokenSilently } = useAuth0();
  const [shippingDetails, setShippingDetails] = useState({
    fullName: '',
    phoneNumber: '',
    email: '',
    address: '',
    city: '',
    postalCode: '',
    country: ''
  });
  const [isSubmitting, setIsSubmitting] = useState(false);
  const [submitStatus, setSubmitStatus] = useState(null);

  const handleChange = (e) => {
    setShippingDetails({ ...shippingDetails, [e.target.name]: e.target.value });
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    setIsSubmitting(true);
    setSubmitStatus(null);
    try {
      const response = await axios.post(`${process.env.REACT_APP_BACKEND_URL}/shippings`, shippingDetails)
      //console.log('Shippings data saved:', response.data);
      setSubmitStatus('success');
      const token = await getAccessTokenSilently();
      //console.log(token);
      
      // Call the onSubmit prop with the shipping details
      onSubmit(shippingDetails);
    } catch (error) {
      console.error('Error saving user data:', error);
      setSubmitStatus('error');
    } finally {
      setIsSubmitting(false);
    }
  };

  if (isLoading) {
    return (
      <div className="flex justify-center items-center h-screen">
        <FontAwesomeIcon icon={faSpinner} spin size="3x" className="text-indigo-600" />
      </div>
    );
  }

  if (!isAuthenticated) {
    return (
      <div className="max-w-md mx-auto p-6 bg-white rounded-lg shadow-xl">
        <h2 className="text-3xl font-semibold text-center text-gray-800 mb-6">Please Sign In</h2>
        <button
          onClick={() => loginWithRedirect()}
          className="w-full px-4 py-2 text-white bg-indigo-600 rounded-lg hover:bg-indigo-700 focus:outline-none focus:ring-4 focus:ring-indigo-500 focus:ring-offset-2 transition duration-300 ease-in-out transform hover:-translate-y-1 hover:scale-105"
        >
          Sign In
        </button>
      </div>
    );
  }

  return (
    <div className="max-w-lg mx-auto p-6 bg-white rounded-lg shadow-xl">
      <h2 className="text-3xl font-semibold text-center text-gray-800 mb-6">Shipping Information</h2>
      <form onSubmit={handleSubmit} className="space-y-4">
        {Object.entries(shippingDetails).map(([key, value]) => (
          <div key={key} className="flex flex-col">
            <label htmlFor={key} className="text-sm font-medium text-gray-700 mb-1">
              {key.charAt(0).toUpperCase() + key.slice(1).replace(/([A-Z])/g, ' $1').trim()}
            </label>
            <input
              id={key}
              name={key}
              type={key === 'email' ? 'email' : 'text'}
              placeholder={getPlaceholder(key)}
              value={value}
              onChange={handleChange}
              required
              className="px-4 py-2 bg-gray-50 border border-gray-300 rounded-lg focus:ring-2 focus:ring-indigo-500 focus:border-indigo-500 transition duration-300 ease-in-out"
            />
          </div>
        ))}
        <button
          type="submit"
          disabled={isSubmitting}
          className={`w-full px-4 py-2 text-white bg-indigo-600 rounded-lg hover:bg-indigo-700 focus:outline-none focus:ring-4 focus:ring-indigo-500 focus:ring-offset-2 transition duration-300 ease-in-out transform hover:-translate-y-1 hover:scale-105 ${
            isSubmitting ? 'opacity-50 cursor-not-allowed' : ''
          }`}
        >
          {isSubmitting ? (
            <FontAwesomeIcon icon={faSpinner} spin className="mr-2" />
          ) : null}
          {isSubmitting ? 'Submitting...' : 'Confirm Shipping'}
        </button>
      </form>
      {submitStatus === 'success' && (
        <div className="mt-4 p-2 bg-green-100 border border-green-400 text-green-700 rounded">
          Shipping information submitted successfully!
        </div>
      )}
      {submitStatus === 'error' && (
        <div className="mt-4 p-2 bg-red-100 border border-red-400 text-red-700 rounded">
          An error occurred while submitting. Please try again.
        </div>
      )}
    </div>
  );
};

const getPlaceholder = (key) => {
  const placeholders = {
    fullName: 'Full as per gov ID',
    phoneNumber: 'mobile number',
    email: 'john.doe@example.com',
    address: 'Assi Ghat',
    city: 'Banaras',
    postalCode: 'Pin Code',
    country: 'India'
  };
  return placeholders[key] || '';
};

export default ShippingForm;