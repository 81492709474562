import React from 'react';
import { FaYoutube, FaFacebookF, FaTwitter, FaInstagram, FaLinkedin } from 'react-icons/fa';

function Footer() {
    const currentYear = new Date().getFullYear();
    
    return (
        <footer className="bg-gradient-to-r from-gray-900 to-gray-800 text-white">
            <div className="max-w-7xl mx-auto px-8 py-16">
                <div className="grid grid-cols-1 md:grid-cols-4 gap-12">
                    <div className="space-y-6">
                        <h3 className="text-3xl font-bold text-green-400">Unique Agrisciences</h3>
                        <p className="text-gray-300 text-sm leading-relaxed">
                            Innovating for sustainable agriculture and a greener future. Our commitment to excellence drives us forward.
                        </p>
                    </div>
                    <div className="space-y-4">
                        <h4 className="text-xl font-semibold text-green-300">Quick Links</h4>
                        <ul className="space-y-2 text-sm">
                            <li><a href="#" className="hover:text-green-400 transition duration-300">About Us</a></li>
                            <li><a href="#" className="hover:text-green-400 transition duration-300">Our Products</a></li>
                            <li><a href="#" className="hover:text-green-400 transition duration-300">Research & Development</a></li>
                            <li><a href="#" className="hover:text-green-400 transition duration-300">Sustainability</a></li>
                        </ul>
                    </div>
                    <div className="space-y-4">
                        <h4 className="text-xl font-semibold text-green-300">Resources</h4>
                        <ul className="space-y-2 text-sm">
                            <li><a href="https://www.indiamart.com/unique-agrisciences/aboutus.html" className="hover:text-green-400 transition duration-300">Blog</a></li>
                            <li><a href="https://economictimes.indiatimes.com/company/unique-agrisciences-india-private-limited/U01403OR2011PTC013490" className="hover:text-green-400 transition duration-300">News & Events</a></li>
                            <li><a href="https://www.indiamart.com/unique-agrisciences/" className="hover:text-green-400 transition duration-300">FAQ</a></li>
                            <li><a href="./Contact.js" className="hover:text-green-400 transition duration-300">Contact Us</a></li>
                        </ul>
                    </div>
                    <div className="space-y-6">
                        <h4 className="text-xl font-semibold text-green-300">Connect With Us</h4>
                        <div className="flex space-x-5">
                            <a href="https://res.cloudinary.com/devunique/video/upload/f_auto:video,q_auto/v1/Product_image/About%20Us/fn4g9kg6pybnnugwudjgs" target="_blank" rel="noopener noreferrer" className="text-white hover:text-red-500 transition duration-300">
                                <FaYoutube size={22} />
                            </a>
                            <a href="#" className="text-white hover:text-blue-400 transition duration-300">
                                <FaFacebookF size={22} />
                            </a>
                            <a href="https://in.linkedin.com/in/prasant-kumar-raut-54834419" className="text-white hover:text-blue-300 transition duration-300">
                                <FaLinkedin size={22} />
                            </a>
                            <a href="#" className="text-white hover:text-pink-400 transition duration-300">
                                <FaInstagram size={22} />
                            </a>
                        </div>
                        <p className="text-sm text-gray-400">
                            Stay updated with our latest news and innovations.
                        </p>
                    </div>
                </div>
                <div className="mt-12 pt-8 border-t border-gray-700 flex flex-col md:flex-row justify-between items-center">
                    <p className="text-sm text-gray-400">&copy; {currentYear} Unique Agrisciences. All rights reserved.</p>
                    <p className="text-sm text-gray-400 mt-4 md:mt-0">
                        Managed by <a href="https://vevacloud.com" target="_blank" rel="noopener noreferrer" className="hover:text-green-400 transition duration-300">Vevacloud Technologies</a>
                    </p>
                </div>
            </div>
        </footer>
    );
}

export default Footer;