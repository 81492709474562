import React, { useState } from 'react';
import { MapPin, Mail, Phone, Send, HelpCircle } from 'lucide-react';

const Contact = () => {
  const [isMapVisible, setIsMapVisible] = useState(false);

  return (
    <div className="min-h-screen bg-cover bg-center bg-fixed relative py-8 px-4 sm:py-12 sm:px-6 lg:px-8 overflow-hidden" style={{backgroundImage: "url('https://res.cloudinary.com/devunique/image/upload/f_auto,q_auto/v1/Product_image/Icons/nfijuuqbbwhrqtr5wxvd')"}}>
      <div className="absolute inset-0 bg-gradient-to-br from-green-400 via-orange-500 to-white-500 opacity-55"></div>
      <div className="max-w-7xl mx-auto relative z-10">
        {/* Animated background elements */}
        <div className="absolute inset-0 overflow-hidden pointer-events-none">
          <div className="galaxy">
            {[...Array(50)].map((_, i) => (
              <div key={i} className="star"></div>
            ))}
          </div>
        </div>

        <h1 className="text-4xl sm:text-5xl font-extrabold text-center mb-8 sm:mb-12 animate-text bg-gradient-to-r from-teal-500 via-white-500 to-orange-500 bg-clip-text text-transparent">
          
        </h1>
        
        <div className="grid grid-cols-1 lg:grid-cols-2 gap-8 lg:gap-12">
          {/* Contact Form */}
          <div className="bg-white bg-opacity-10 backdrop-filter backdrop-blur-lg rounded-xl shadow-2xl p-6 sm:p-8 transform transition duration-500 hover:scale-105 animate-float">
            <h2 className="text-2xl sm:text-3xl font-bold text-white mb-6">Reach Out</h2>
            <form className="space-y-6">
              <div className="group">
                <input
                  className="w-full bg-transparent border-b-2 border-white p-3 text-white placeholder-white placeholder-opacity-70 focus:outline-none focus:border-purple-300 transition duration-300"
                  id="name"
                  type="text"
                  placeholder="Your Name"
                />
              </div>
              <div className="group">
                <input
                  className="w-full bg-transparent border-b-2 border-white p-3 text-white placeholder-white placeholder-opacity-70 focus:outline-none focus:border-purple-300 transition duration-300"
                  id="email"
                  type="email"
                  placeholder="Your Email"
                />
              </div>
              <div className="group">
                <textarea
                  className="w-full bg-transparent border-b-2 border-white p-3 text-white placeholder-white placeholder-opacity-70 focus:outline-none focus:border-purple-300 transition duration-300"
                  id="message"
                  rows="4"
                  placeholder="Your Message"
                />
              </div>
              <button
                className="w-full bg-white text-purple-600 py-3 rounded-full font-medium hover:bg-purple-100 transition duration-300 transform hover:scale-105 flex items-center justify-center space-x-2"
                type="button"
              >
                <span>Send Message</span>
                <Send className="w-5 h-5" />
              </button>
            </form>
          </div>

          <div className="space-y-8">
            {/* Animated Map and Contact Details */}
            <div className="bg-white bg-opacity-10 backdrop-filter backdrop-blur-lg rounded-xl shadow-2xl overflow-hidden transform transition duration-500 hover:scale-105 animate-float">
              <div 
                className="h-48 sm:h-64 w-full relative cursor-pointer"
                onMouseEnter={() => setIsMapVisible(true)}
                onMouseLeave={() => setIsMapVisible(false)}
                onClick={() => setIsMapVisible(!isMapVisible)}
              >
                <div className={`absolute inset-0 transition-opacity duration-500 ${isMapVisible ? 'opacity-100' : 'opacity-0'}`}>
                  <iframe
                    title="Google Maps"
                    className="w-full h-full"
                    src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d3744.10554987516!2d85.85347547377108!3d20.212934515301498!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x3a19a160195a6177%3A0x5ec6d38811233a25!2sUnique%20Agrisciences%20Corporate%20office!5e0!3m2!1sen!2sin!4v1721379472414!5m2!1sen!2sin"
                    allowFullScreen=""
                    loading="lazy"
                  ></iframe>
                </div>
                <div className={`absolute inset-0 flex items-center justify-center transition-opacity duration-500 ${isMapVisible ? 'opacity-0' : 'opacity-100'}`}>
                  <MapPin className="w-12 h-12 sm:w-16 sm:h-16 text-white animate-bounce" />
                </div>
              </div>
              <div className="p-6 sm:p-8">
                <h2 className="text-2xl sm:text-3xl font-bold text-white mb-6">Find us above</h2>
                <div className="space-y-4">
                  <div className="flex items-start sm:items-center text-white">
                    <MapPin className="w-5 h-5 text-purple-300 mr-3 mt-1 sm:mt-0 flex-shrink-0" />
                    <p className="text-sm sm:text-base">Plot No.15, Soubhagya Nagar, Bhubaneswar, Odisha-751003, India</p>
                  </div>
                  <div className="flex items-center text-white">
                    <Mail className="w-5 h-5 text-purple-300 mr-3 flex-shrink-0" />
                    <p className="text-sm sm:text-base">contact@yourcompany.com</p>
                  </div>
                  <div className="flex items-center text-white">
                    <Phone className="w-5 h-5 text-purple-300 mr-3 flex-shrink-0" />
                    <p className="text-sm sm:text-base">86584 21058</p>
                  </div>
                </div>
              </div>
            </div>

            {/* Ask Me Block */}
            <div className="bg-white bg-opacity-10 backdrop-filter backdrop-blur-lg rounded-xl shadow-2xl p-6 sm:p-8 transform transition duration-500 hover:scale-105 animate-float">
              <h2 className="text-2xl sm:text-3xl font-bold text-white mb-6 flex items-center">
                <HelpCircle className="w-8 h-8 mr-3 text-purple-300" />
                Ask Me
              </h2>
              <form className="space-y-4">
                <textarea
                  className="w-full bg-transparent border-2 border-white rounded-lg p-3 text-white placeholder-white placeholder-opacity-70 focus:outline-none focus:border-purple-300 transition duration-300"
                  rows="3"
                  placeholder="Type your question here..."
                />
                <button
                  className="w-full bg-purple-600 text-white py-3 rounded-full font-medium hover:bg-purple-700 transition duration-300 transform hover:scale-105 flex items-center justify-center space-x-2"
                  type="button"
                >
                  <span>Ask Question</span>
                  <Send className="w-5 h-5" />
                </button>
              </form>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Contact;