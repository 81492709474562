import React from 'react';
import { useCart } from '../context/CartContext';
import CartItem from './CartItem';
import { useNavigate } from 'react-router-dom';
import { useAuth0 } from '@auth0/auth0-react';
import { ShoppingCart, Lock, ArrowRight } from 'lucide-react';

const Cart = () => {
  const { cart } = useCart();
  const navigate = useNavigate();
  const cartTotal = cart.reduce((total, item) => total + item.price * item.quantity, 0);
  const { isAuthenticated, loginWithRedirect } = useAuth0();

  const handleCheckout = () => {
    navigate('/checkout');
  };

  return (
    <div className="min-h-screen bg-gradient-to-br from-purple-100 via-pink-100 to-rose-100 py-12 px-4 sm:px-6 lg:px-8"
    style={{
      backgroundImage: `url('https://res.cloudinary.com/devunique/image/upload/f_auto,q_auto/v1/Product_image/Icons/p1')`,
    }}>
      <div className="max-w-7xl mx-auto">
        <h1 className="text-4xl md:text-5xl font-extrabold text-gray-900 mb-8 text-center">
          
        </h1>
        <div className="flex flex-col lg:flex-row gap-8">
          {/* Cart Items Section */}
          <div className="w-full lg:w-2/3 bg-white rounded-2xl shadow-xl overflow-hidden border border-pink-200">
            {cart.length === 0 ? (
              <div className="p-12 text-center">
                <ShoppingCart className="text-pink-400 w-24 h-24 mx-auto mb-6" />
                <p className="text-2xl text-gray-600 mb-6">Your cart is as empty as a desert mirage!</p>
                <button 
                  onClick={() => navigate('/products')}
                  className="mt-6 bg-gradient-to-r from-pink-500 to-rose-500 hover:from-pink-600 hover:to-rose-600 text-white font-semibold py-3 px-6 rounded-full transition duration-300 transform hover:scale-105"
                >
                  Discover Amazing Products
                </button>
              </div>
            ) : (
              <ul className="divide-y divide-pink-100">
                {cart.map((item) => (
                  <li key={item.id} className="p-6 hover:bg-pink-50 transition duration-300">
                    <CartItem item={item} />
                  </li>
                ))}
              </ul>
            )}
          </div>

          {/* Cart Summary Section */}
          <div className="w-full lg:w-1/3 bg-white rounded-2xl shadow-xl overflow-hidden border border-pink-200">
            <div className="p-8">
              <h2 className="text-3xl font-bold text-gray-900 mb-6">Order Summary</h2>
              <div className="flex justify-between mb-4 text-lg">
                <span className="text-gray-600">Subtotal</span>
                <span className="font-semibold">₹{cartTotal.toFixed(2)}</span>
              </div>
              <div className="flex justify-between mb-4 text-lg">
                <span className="text-gray-600">Shipping</span>
                <span className="font-semibold text-green-500">After Confirmation</span>
              </div>
              <div className="border-t-2 border-pink-200 mt-6 pt-6">
                <div className="flex justify-between">
                  <span className="text-2xl font-bold text-gray-900">Total</span>
                  <span className="text-2xl font-bold text-pink-600">₹{cartTotal.toFixed(2)}</span>
                </div>
              </div>
              <div className="mt-8">
                <label className="inline-flex items-center">
                  <input type="checkbox" className="form-checkbox text-pink-500 rounded" />
                  <span className="ml-2 text-gray-700">I agree to the <a href="#" className="text-pink-500 hover:underline">Terms & Conditions. *GST Included.</a></span>
                </label>
              </div>
              {isAuthenticated ? (
                <div className="mt-8 space-y-4">
                  <button 
                    className="w-full bg-gradient-to-r from-pink-500 to-rose-500 hover:from-pink-600 hover:to-rose-600 text-white font-bold py-4 px-6 rounded-xl transition duration-300 flex items-center justify-center transform hover:scale-105"
                    onClick={handleCheckout}
                  >
                    <Lock className="mr-2" size={20} />
                    Secure Checkout
                  </button>
                  <button className="w-full border-2 border-gray-300 text-gray-700 font-semibold py-4 px-6 rounded-xl hover:bg-gray-50 transition duration-300 transform hover:scale-105">
                    Request Bulk Order
                  </button>
                </div>
              ) : (
                <div className="mt-8 bg-gradient-to-r from-blue-50 to-purple-50 border border-blue-200 rounded-xl p-6">
                  <h3 className="text-xl font-semibold text-blue-800 mb-3">Sign In to Checkout</h3>
                  <p className="text-blue-600 mb-6">Unlock exclusive deals by signing in!</p>
                  <button
                    onClick={() => loginWithRedirect()}
                    className="w-full bg-gradient-to-r from-blue-500 to-purple-500 hover:from-blue-600 hover:to-purple-600 text-white font-bold py-4 px-6 rounded-xl transition duration-300 flex items-center justify-center transform hover:scale-105"
                  >
                    Sign In
                    <ArrowRight className="ml-2" size={20} />
                  </button>
                </div>
              )}
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Cart;