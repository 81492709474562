import React from 'react';
import ReactDOM from 'react-dom/client';
import './index.css';
import App from './App';
import reportWebVitals from './reportWebVitals';
import { BrowserRouter as Router } from 'react-router-dom';
import { Auth0Provider } from '@auth0/auth0-react';
import { auth0Config } from './auth0Config';

const root = ReactDOM.createRoot(document.getElementById('root'));

const onRedirectCallback = (appState) => {
  window.history.replaceState(
    {},
    document.title,
    appState?.returnTo || window.location.pathname
  );
};

root.render(
    <Router>
      <Auth0Provider
        domain={auth0Config.domain}
        clientId={auth0Config.clientId}
        authorizationParams={{ redirect_uri: auth0Config.authorizationParams.redirect_uri }}
        onRedirectCallback={onRedirectCallback}
        cacheLocation="localstorage"
        onError={error => console.error('Auth0 error:', error)}
        audience="openid profile email"
        scope="This is a unique identifier"
      >
        <App />
      </Auth0Provider>
    </Router>
  
);

reportWebVitals();
