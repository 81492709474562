import React from 'react';
import { useNavigate } from 'react-router-dom';
import SmallImageSlider from './SmallImageSlider';
import { useCart } from '../context/CartContext';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faShoppingCart, faInfoCircle } from '@fortawesome/free-solid-svg-icons';

const ProductCard = ({ product }) => {
  const navigate = useNavigate();
  const { addToCart } = useCart();

  const handleAddToCart = (e) => {
    e.stopPropagation();
    addToCart(product);
  };

  const handleViewDetails = () => {
    navigate(`/products/${product.id}`);
  };

  return (
    <div className=" bg-white bg-opacity-95 rounded-lg overflow-hidden shadow-lg hover:shadow-2xl transition-all duration-300 transform hover:-translate-y-1 flex flex-col h-full">
      <div className="relative pb-2/3">
        <SmallImageSlider images={product.images} interval={3000} />
      </div>
      <div className="p-6 flex flex-col flex-grow">
        <h3 className="font-bold text-2xl mb-3 text-gray-800 hover:text-green-600 transition-colors duration-200 line-clamp-2">{product.name}</h3>
        <p className="text-gray-600 text-base mb-4 flex-grow line-clamp-3">{product.description}</p>
        <div className="flex items-center justify-between mt-auto">
          <span className="text-green-600 font-bold text-3xl">₹{product.price.toFixed(2)}</span>
          <div className="flex space-x-2">
            <button
              onClick={handleAddToCart}
              className="bg-green-500 hover:bg-green-600 text-white font-medium py-2 px-4 rounded-full transition-colors duration-200 flex items-center shadow-md hover:shadow-lg"
            >
              <FontAwesomeIcon icon={faShoppingCart} className="mr-2" />
              <span>Add</span>
            </button>
            <button
              onClick={handleViewDetails}
              className="bg-blue-500 hover:bg-blue-600 text-white font-medium py-2 px-4 rounded-full transition-colors duration-200 flex items-center shadow-md hover:shadow-lg"
            >
              <FontAwesomeIcon icon={faInfoCircle} className="mr-2" />
              <span>Details</span>
            </button>
          </div>
        </div>
      </div>
    </div>
  );
};

export default ProductCard;